import axios from "axios"
import { toast } from "sonner"

import { getHeaders } from "@/utils/headers"

const URI_API = process.env.NEXT_PUBLIC_API_URL

const api = axios.create({
  baseURL: URI_API,
})

const isServer = typeof window === "undefined"

api.interceptors.request.use(async (config) => {
  if (isServer) {
    const { headers, cookies } = await import("next/headers")
    const headersInstance = await headers()
    const cookiesInstance = await cookies()

    const slug = headersInstance.get("x-institution-subdomain")
    const appToken = headersInstance.get("x-token-institution")
    const token = cookiesInstance.get(`@${slug}:token`)?.value

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`
    }
    if (appToken) {
      config.headers["Token"] = appToken as string
    }
  } else {
    const { appToken, token } = await getHeaders()
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`
    }
    if (appToken) {
      config.headers["Token"] = appToken as string
    }
  }
  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data"
    config.data.forEach((value, key) => {
      if (value === "" || value === undefined) {
        config.data.set(key, null)
      }

      return config
    })
  }

  const data = config.data
  if (data) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] === undefined) {
        data[key] = null
      }
    })
  }
  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.log({
      "ERROR DATA": error.response.data,
      "ERROR STATUS": error.response.status,
      "ERROR URL": error.config.url,
      "ERROR HEADERS": error.config.headers,
    })
    // if (error.response.status === 401) {
    //   if (!isServer) {
    //     toast.error("Sessão expirada, faça login novamente.")
    //     redirect("/sair")
    //   }
    //   redirect("/sair")
    // }
    if (error.response) {
      if (error.response.data.message) {
        if (!isServer) {
          toast.error(error.response.data.message)
          return Promise.reject(error)
        }
      }
    }
    return Promise.reject(error)
  }
)

export default api
