import { useState } from "react"
import { useController, type Control, type FieldPath, type FieldValues } from "react-hook-form"
import { FaEye, FaEyeSlash } from "react-icons/fa"

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input as InputComponent } from "@/components/ui/input"
import { cn } from "@/lib/utils"

interface InputProps<TFieldValues extends FieldValues> extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  placeholder?: string
  isRequired?: boolean
  normalize?: (value: string) => string
  icon?: React.ReactNode
  description?: string
  name: FieldPath<TFieldValues>
  control: Control<TFieldValues>
  funcAction?: (data: string) => void
  disabled?: boolean
}

const Input = <TFieldValues extends FieldValues = FieldValues>({
  label,
  name,
  placeholder,
  isRequired,
  normalize,
  icon,
  description,
  control,
  funcAction,
  disabled = false,
  ...props
}: InputProps<TFieldValues>) => {
  const [showPassword, setShowPassword] = useState(false)
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    control,
    name,
    defaultValue: undefined,
  })
  return (
    <FormField
      control={control}
      name={name}
      render={() => {
        return (
          <FormItem className="relative w-full">
            <FormLabel>
              {label}
              {isRequired && <span className="text-red-500"> *</span>}
            </FormLabel>
            <FormControl>
              <InputComponent
                disabled={isSubmitting || disabled}
                placeholder={placeholder ?? "Digite..."}
                {...field}
                {...props}
                onBlur={(event) => {
                  field.onBlur()
                  props.onBlur?.(event)
                }}
                onChange={(event) => {
                  if (props.type === "number") {
                    return field.onChange(Number(event.target.value))
                  }
                  field.onChange(event.target.value)
                  funcAction && funcAction(event.target.value)
                }}
                type={props.type === "password" && showPassword ? "text" : props.type}
                value={(normalize ? normalize(field.value) : field.value) ?? ""}
              />
            </FormControl>
            {props.type === "password" && (
              <button
                type="button"
                onClick={() => setShowPassword((prev) => !prev)}
                className="absolute right-2 top-1/2 mt-0.5"
              >
                {showPassword ? <FaEyeSlash className="size-4" /> : <FaEye className="size-4" />}
              </button>
            )}
            {icon && <div className="absolute bottom-0 right-0 top-6 flex items-center pr-3">{icon}</div>}
            <FormMessage />
            {description && <FormDescription className={cn("absolute mt-1 text-xs")}>{description}</FormDescription>}
          </FormItem>
        )
      }}
    />
  )
}

export default Input
