"use client"

import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { zodResolver } from "@hookform/resolvers/zod"
import { X } from "lucide-react"
import { toast } from "sonner"
import { z } from "zod"

import type { IUser } from "@/interfaces/user"
import { Link } from "@/lib/router-events"
import { signIn } from "@/services/auth"
import { putCart } from "@/services/cart"
import useAuth from "@/store/useAuth"
import { useCartStore } from "@/store/useCart"
import useInstitution from "@/store/useInstitution"

import { FormProvider } from "../hook-form/form-provider"
import Input from "../hook-form/input"
import { Button } from "../ui/button"
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog"

// Memoize DialogContent
const MemoizedDialogContent = React.memo(DialogContent)

const schema = z.object({
  email: z
    .string({
      required_error: "E-mail é obrigatório",
    })
    .email({
      message: "E-mail inválido",
    }),
  password: z
    .string({
      required_error: "Senha é obrigatória",
    })
    .min(6, {
      message: "Senha deve ter no mínimo 6 caracteres",
    }),
})

interface FormLoginProps {
  email: string
  password: string
}

export const ModalLogin = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { loadCart, cart } = useCartStore()
  const { institution } = useInstitution()
  const { setUser, setOpenLogin, openLogin } = useAuth()

  const methods = useForm<FormLoginProps>({
    resolver: zodResolver(schema),
  })

  const { handleSubmit, control } = methods

  const onSubmit = async (data: FormLoginProps) => {
    setIsLoading(true)
    await signIn({
      email: data.email,
      password: data.password,
      slug: institution.slug,
    })
      .then(async (response) => {
        setUser(response as IUser)
        toast.success("Login efetuado com sucesso", { position: "top-center" })
        setIsLoading(false)
        setOpenLogin(false)
        if (cart && response) {
          await putCart({ cartId: cart.id, userId: response.id })
          loadCart()
        }
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }
  return (
    <Dialog open={openLogin} onOpenChange={setOpenLogin}>
      <MemoizedDialogContent>
        <DialogHeader className="gap-4">
          <div className="flex items-center justify-between">
            <DialogTitle>ENTRAR</DialogTitle>
            <DialogClose>
              <X className="size-4" />
            </DialogClose>
          </div>
          <FormProvider methods={methods}>
            <div className="flex flex-col gap-4">
              <Input name="email" label="E-mail" isRequired control={control} />
              <Input name="password" label="Senha" type="password" control={control} isRequired />

              <div className="flex w-full justify-end">
                <Link href="recuperar-senha" className="text-sm text-gray-700">
                  Esqueci minha senha
                </Link>
              </div>

              <Button disabled={isLoading} onClick={handleSubmit(onSubmit)} className="w-full">
                Entrar
              </Button>

              {institution?.config?.users_can_register === 1 && (
                <div className="mt-4 text-center text-sm">
                  <span>
                    Não tem uma conta?{" "}
                    <Link href="cadastro" className="font-bold">
                      Cadastre-se
                    </Link>
                  </span>
                </div>
              )}
            </div>
          </FormProvider>
        </DialogHeader>
      </MemoizedDialogContent>
    </Dialog>
  )
}
