"use client"

import React, { useEffect } from "react"
import type { FieldValues, UseFormReturn } from "react-hook-form"
import { FormProvider as RHFFormProvider } from "react-hook-form"

import { toast } from "sonner"

interface FormProviderProps<TFormValues extends FieldValues> {
  children: React.ReactNode
  methods: UseFormReturn<TFormValues>
}

export const FormProvider = <TFormValues extends FieldValues>({
  children,
  methods,
}: FormProviderProps<TFormValues>) => {
  const {
    formState: { errors },
  } = methods

  useEffect(() => {
    if (errors) {
      Object.keys(errors).forEach((key) => {
        if (errors[key]?.message) {
          toast.error(String(errors[key].message))
        }
      })
    }
  }, [errors])
  return <RHFFormProvider {...methods}>{children}</RHFFormProvider>
}
