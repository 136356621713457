import { createCookie } from "@/app/actions"
import type { ProfileUser } from "@/components/form/form-meus-dados/utils/validation"
import type { Institution } from "@/interfaces/institution"
import { IUser } from "@/interfaces/user"

import api from "./api-server"

interface SignInCredentials {
  email: string
  password: string
  slug: string
}

interface AuthResponse {
  token: {
    expires_at: string
    token: string
    type: string
  }
  user: IUser
}

export const signIn = async ({ email, password, slug }: SignInCredentials) => {
  return await api
    .post<{
      token: {
        expires_at: string
        token: string
        type: string
      }
      user: IUser
      roles: string[]
    }>("/sessions", {
      email,
      password,
      slug,
    })
    .then(async (response) => {
      const { token, user, roles } = response.data
      if (token) {
        const _user = {
          ...user,
          roles,
          sessionToken: token.token,
        }

        await createCookie({
          name: `@${slug}:user`,
          httpOnly: true,
          maxAge: 60 * 60 * 24, // 1 day
          value: JSON.stringify(_user),
        })

        await createCookie({
          name: `@${slug}:token`,
          httpOnly: true,
          maxAge: 60 * 60 * 24, // 1 day
          value: token.token,
        })

        localStorage.setItem(`@${slug}:user`, JSON.stringify(_user))
      }
      if (roles) {
        return {
          ...user,
          roles,
          sessionToken: token.token,
        }
      }
      return user
    })
    .catch((error) => {
      throw new Error(error.response.data.message)
    })
}

export const loadPermissions = async () => {
  return await api.get<{ permissions: string[] }>("/admin/users/me/permissions").then(({ data }) => data.permissions)
}

export const loadPermissionsInstitution = async (): Promise<any> => {
  return await api.get("/admin/permissions").then(({ data }) => data)
}

export const sendRecoveryEmail = async ({ document, slug }: { document: string; slug: string }) => {
  return await api.post("/forgot-email", {
    document,
    slug,
  })
}

export const sendRecoveryPassword = async ({ email, slug, url }: { email: string; slug: string; url: string }) => {
  return await api.post("/forgot-password", {
    email,
    slug,
    reset_password_url: url,
  })
}

export const recoveryPassword = async ({ password, token }: { password: string; token: string }) => {
  return await api.post("/reset-password", {
    password,
    token,
  })
}

export const validateToken = async (token: string) => {
  return await api.get(`/reset-password/${token}`)
}

export const getMe = async () => {
  return await api
    .get<{
      user: IUser
      institution: Institution
      roles: {
        created_at: string
        deleted_at_unix: number
        description: string
        id: number
        institution_id: number
        name: string
        protected: number
        updated_at: string
      }[]
    }>(`/user-me`)
    .then(({ data }) => data)
}

export const updateProfile = async (profile: ProfileUser) => {  
  return await api.put<IUser>(`/admin/user-profile/${profile.id}`, profile).then(async ({ data }) => {
    return data
  })
}

export const destroyAvatarProfile = async (id: number) => {
  return await api.delete(`/admin/user-profile/avatar/${id}`)
}

export const updateAvatarProfile = async (id: number, avatar: File) => {
  const formData = new FormData();
    
  if (avatar instanceof File) {
    formData.append("avatar", avatar);
    return await api.put(`/admin/user-profile/avatar/${id}`, formData);
  }
};

