import { sendGTMEvent } from "@next/third-parties/google"

import { toast } from "sonner"
import { create } from "zustand"

import { clearCookies } from "@/app/actions"
import type { ICart } from "@/interfaces/cart"
import type { IProduct } from "@/interfaces/product"
import type { IUser } from "@/interfaces/user"
import api from "@/services/api"

interface CreditForm {
  number: string
  holder_name: string
  exp_month: string
  exp_year: string
  cvv: string
  installment: string
}

export enum PaymentMethod {
  CREDIT_CARD = 1,
  PIX = 2,
  BOLETO = 3,
}

interface CartState {
  cart: ICart | null
  coupon: string
  setCart: (cart: ICart | null) => void
  setCoupon: (coupon: string) => void
  loadCart: () => void
  handleApplyCoupon: (value: string) => void
  handleRemoveCoupon: (value: number) => void
  handleAddToCart: (product: IProduct) => void
  handleRemoveToCart: (id: number) => void
  step: number
  setStep: (step: number) => void
  creditCardForm: CreditForm | null
  setCreditCardForm: (creditCardForm: CreditForm) => void
  openCart: boolean
  setOpenCart: (value: boolean) => void
  isLoading: boolean
  setIsLoading: (value: boolean) => void
  url: string
  setUrl: (url: string) => void
  handlePayment: ({ type_payment_id }: { type_payment_id: PaymentMethod }) => void
  handleHotmart: () => Promise<string | undefined>
  handlePUTCart: (user: IUser) => void
  handleRemoveUserCart: () => Promise<void>
  clearCart: () => void
}

export const useCartStore = create<CartState>((set, get) => {
  const isClient = typeof window !== "undefined" && window.localStorage

  const slug =
    typeof window !== "undefined" && window.location.host.replace(`.${process.env.NEXT_PUBLIC_ROOT_DOMAIN}`, "")

  const cart = isClient ? (JSON.parse(localStorage.getItem(`@${slug}:cart`) as string) as ICart) : null

  const url = isClient ? (localStorage.getItem(`@${slug}:url`) as string) : ""
  return {
    isLoading: false,
    setIsLoading: (value) => set({ isLoading: value }),
    cart: cart,
    coupon: cart?.coupon?.code || "",
    setCart: (cart) => set({ cart }),
    setCoupon: (coupon) => set({ coupon }),
    loadCart: async () => {
      const cart = get().cart
      if (!!cart) {
        await api.get(`/carts/${cart.id}`).then(({ data }) => {
          set({ cart: data })
          localStorage.setItem(`@${slug}:cart`, JSON.stringify(data))
        })
      }
    },
    handleApplyCoupon: async (value) => {
      set({ isLoading: true })
      await api
        .post("coupons", {
          code: value,
          cart_id: get().cart?.id,
          user_id: get().cart?.user_id,
        })
        .then(() => {
          get().loadCart()
          set({ isLoading: false })
          toast.success("Cupom aplicado com sucesso")
        })
        .catch(() => {
          set({ isLoading: false })
        })
    },
    handleRemoveCoupon: async (value) => {
      set({ isLoading: true })
      await api
        .delete(`coupons/${value}`)
        .then(() => {
          toast.success("Cupom removido com sucesso")
          get().loadCart()
          set({ coupon: "" })
          set({ isLoading: false })
        })
        .catch(() => {
          set({ isLoading: false })
        })
    },
    handleAddToCart: async (product: IProduct) => {
      const cart = get().cart
      get().setIsLoading(true)
      // const user =
      //   typeof window !== "undefined" && (JSON.parse(window.localStorage.getItem(`@${slug}:user`) as string) as IUser)
      if (!cart) {
        await api.post("/carts").then(async ({ data }) => {
          await api
            .post(`/carts/${data.id}/products`, {
              id: product.id,
              qtd: 1,
            })
            .then(({ data: cart }) => {
              localStorage.setItem(`@${slug}:cart`, JSON.stringify(cart))
              set({ cart })
              sendGTMEvent({
                event: "add_to_cart",
                ecommerce: {
                  currency: "BRL",
                  value: Number((product.price_discount / 100).toFixed(2)) || Number((product.price / 100).toFixed(2)),
                  items: [
                    {
                      item_id: product.id,
                      item_name: product.name,
                      discount:
                        product.price_discount > 0
                          ? Number(((product.price - product.price_discount) / 100).toFixed(2))
                          : 0,
                      item_category: product?.categories[0]?.name,
                      item_list_id: product.slug,
                      item_list_name: product.name,
                      price: Number((product.price / 100).toFixed(2)),
                      quantity: 1,
                    },
                  ],
                },
              })
              get().setOpenCart(true)
              get().setIsLoading(false)
            })
        })
        // await api.post("/carts").then(async ({ data }) => {
        //   await api
        //     .put(`/carts/${data?.id}`, {
        //       user_id: user.id,
        //     })
        //     .then(async ({ data: dataPUT }) => {
        //       await api
        //         .post(`/carts/${dataPUT.id}/products`, {
        //           id: product.id,
        //           qtd: 1,
        //         })
        //         .then(({ data: cart }) => {
        //           localStorage.setItem(`@${slug}:cart`, JSON.stringify(cart))
        //           set({ cart })
        //           sendGTMEvent({
        //             event: "add_to_cart",
        //             ecommerce: {
        //               currency: "BRL",
        //               value:
        //                 Number((product.price_discount / 100).toFixed(2)) || Number((product.price / 100).toFixed(2)),
        //               items: [
        //                 {
        //                   item_id: product.id,
        //                   item_name: product.name,
        //                   discount:
        //                     product.price_discount > 0
        //                       ? Number(((product.price - product.price_discount) / 100).toFixed(2))
        //                       : 0,
        //                   item_category: product?.categories[0]?.name,
        //                   item_list_id: product.slug,
        //                   item_list_name: product.name,
        //                   price: Number((product.price / 100).toFixed(2)),
        //                   quantity: 1,
        //                 },
        //               ],
        //             },
        //           })
        //           toast.success("Produto adicionado ao carrinho")
        //         })
        //     })
        // })
      } else {
        await api.post(`/carts/${cart?.id}/products`, { id: product.id, qtd: 1 }).then(({ data }) => {
          localStorage.setItem(`@${slug}:cart`, JSON.stringify(data))
          set({ cart: data })
          sendGTMEvent({
            event: "add_to_cart",
            ecommerce: {
              currency: "BRL",
              value: Number((product.price_discount / 100).toFixed(2)) || Number((product.price / 100).toFixed(2)),
              items: [
                {
                  item_id: product.id,
                  item_name: product.name,
                  discount:
                    product.price_discount > 0
                      ? Number(((product.price - product.price_discount) / 100).toFixed(2))
                      : 0,
                  item_category: product?.categories[0]?.name,
                  item_list_id: product.slug,
                  item_list_name: product.name,
                  price: Number((product.price / 100).toFixed(2)),
                  quantity: 1,
                },
              ],
            },
          })
          get().setOpenCart(true)
          get().setIsLoading(false)
        })
      }
    },
    handleRemoveToCart: async (id: number) => {
      const cart = get().cart
      if (!cart) return
      await api
        .delete(`/carts/${cart.id}/products/${id}`)
        .then(() => {
          toast.success("Produto removido do carrinho")
          get().loadCart()
        })
        .catch(() => {
          toast.error("Erro ao remover produto do carrinho")
        })
    },
    handlePUTCart: async (user) => {
      const cart = get().cart
      await api
        .put(`/carts/${cart?.id}`, {
          user_id: user.id,
        })
        .then(({ data }) => {
          set({ cart: data })
          localStorage.setItem(`@${slug}:cart`, JSON.stringify(data))
        })
    },
    handleRemoveUserCart: async () => {
      const cart = get().cart
      await api.put(`/carts/logout/${cart?.id}`).then(({ data }) => {
        set({ cart: data })
        localStorage.setItem(`@${slug}:cart`, JSON.stringify(data))
        localStorage.removeItem(`@${slug}:user`)
        clearCookies()
      })
    },
    clearCart: () => {
      localStorage.removeItem(`@${slug}:cart`)
      set({ cart: null })
    },
    step: 1,
    setStep: (step) => set({ step }),
    creditCardForm: null,
    setCreditCardForm: (creditCardForm) => set({ creditCardForm }),
    openCart: false,
    setOpenCart: (value: boolean) => set({ openCart: value }),
    url: url,
    setUrl: (url) => {
      localStorage.setItem(`@${slug}:url`, url)
      set({ url })
    },
    handlePayment: async ({ type_payment_id }: { type_payment_id: PaymentMethod }) => {
      const cart = get().cart
      set({ isLoading: true })
      if (!cart) return
      const { data } = await api.post("orders", {
        cart_id: cart.id,
      })
      set({ isLoading: false })
      const { data: datPayment } = await api.post("order/payments", {
        order_id: data.id,
        type_payment_id: type_payment_id,
      })
      set({ isLoading: false })
    },
    handleHotmart: async () => {
      set({ isLoading: true })
      const cart = get().cart
      const { data } = await api.post<{ url: string }>(`/orders/hotmart/${cart?.id}`)
      if (data.url) {
        set({ isLoading: false })
        set({ url: data.url })
        return data.url
      }
    },
  }
})
