import { redirect } from "next/navigation"

import { create } from "zustand"

import { Institution } from "@/interfaces/institution"
import { clearData } from "@/lib/utils"
import api from "@/services/api"
import { IRoute } from "@/utils/routes"

export enum sidebarModeEnum {
  STUDENT = "studdent",
  ADMIN = "admin",
}

interface IInstitutionProps {
  institution: Institution
  setInstitution: (value: Institution) => void
  menus: IRoute[]
  setMenus: (value: IRoute[]) => void
  sidebarMode: sidebarModeEnum
  setSidebarMode: (value: sidebarModeEnum) => void
  institutions: Institution[]
  setInstitutions: (value: Institution[]) => void
  setStep: (value: string | null) => void
  step: string | null
  fetchInstitutions: () => void
  changeInstitution: ({ user_id, instituition }: { user_id: number; instituition: Institution }) => void
  openFilter: boolean
  setOpenFilter: (value: boolean) => void
}

const useInstitution = create<IInstitutionProps>((set) => {
  const isClient = typeof window !== "undefined" && window.localStorage

  const sidemode = isClient ? (localStorage.getItem(`sidebarMode`) as sidebarModeEnum) : null

  return {
    institution: {} as Institution,
    setInstitution: (value: Institution) => set({ institution: value }),
    menus: [],
    setMenus: (value: IRoute[]) => set({ menus: value }),
    sidebarMode: sidemode ?? sidebarModeEnum.STUDENT,
    setSidebarMode: async (value: sidebarModeEnum) => {
      // await createCookie("sidebarMode", value)
      localStorage.setItem("sidebarMode", value)
      set({ sidebarMode: value })
    },
    institutions: [],
    setInstitutions: (value: Institution[]) => set({ institutions: value }),
    setStep: (value: string | null) => set({ step: value }),
    step: null,
    fetchInstitutions: async () => {
      await api
        .get("/admin/institutions-change")
        .then(({ data }) => {
          set({ institutions: data })
        })
        .catch(() => {
          clearData()
          redirect("/login")
        })
    },
    changeInstitution: async ({ user_id, instituition }: { user_id: number; instituition: Institution }) => {
      await api.post(`/admin/user/tokens`, { user_id }).then(async (response) => {
        await api.post(`/sessions/user-token`, { token: response.data }).then((res) => {
          const link = `${window.location.protocol}//${instituition.slug}.${window.location.host.split(".")[1]}.com.brpnpm /token/${res.data.token.token}`
          window.open(link, "_blank")
        })
      })
    },
    openFilter: false,
    setOpenFilter: (value: boolean) => set({ openFilter: value }),
  }
})
// href={`${window.location.protocol}//${row.original.slug}.${window.location.host.split(".")[1]}`}
export default useInstitution
